import React from 'react';

// images
import Image from '../assets/profile.svg';

//icons
import { FaGithub, FaLinkedin, FaFacebook } from 'react-icons/fa';

//type animation
import { TypeAnimation } from 'react-type-animation';

//motion
import { motion } from 'framer-motion';

//variants
import { fadeIn } from '../variants';


const scrollToContact = () => {
  const contactSection = document.getElementById('contact');
  if (contactSection) {
    contactSection.scrollIntoView({ behavior: 'smooth' });
  }
};

const Banner = () => {
  return (
    <section className='min-h-[85vh] lg:min-h-[78vh] flex items-center' id='home'>
      <div className='container mx-auto'>
        <div className='flex flex-col gap-y-8 lg:flex-row lg:items-center lg:gap-x-12'>
          {/* text */}

          <div className='flex-1 text-center font-secondary lg:text-left'>

            <motion.h1 variants={fadeIn('up', 0.3)} initial='hidden' whileInView={'show'} viewport={{once: false, amount: 0.7}} className='text-[55px] font-bold leading-[0.8] lg:text-[110px]'>
              SUHEJB <span>MUSLIU</span>
            </motion.h1>

            <motion.div variants={fadeIn('up', 0.4)} initial='hidden' whileInView={'show'} viewport={{once: false, amount: 0.7}}  className='mb-6 text-[36px] lg:text-[60px] font-secondary font-semibold uppercase leading-[1]'>

              <span className='text-white mr-4'>I am a</span>

              <TypeAnimation sequence={[
                'Developer',
                2000,
                'Designer',
                2000,
                'Logo-Designer',
                2000,
              ]}
              speed={50}
              className='text-accent'
              wrapper='span'
              repeat={Infinity}
              />

            
            </motion.div>

            <motion.p variants={fadeIn('up', 0.5)} initial='hidden' whileInView={'show'} viewport={{once: false, amount: 0.7}} className='mb-8 max-w-lg mx-auto lg:mx-0 text-2xl'>
              Proficient in coding for webdeveloping. I am enhancing my ability to create dynamic and user-friendly web applications. 
            </motion.p>

              <motion.div variants={fadeIn('up', 0.6)} initial='hidden' whileInView={'show'} viewport={{once: false, amount: 0.7}}  className='flex max-w-max gap-x-6 items-center mb-12 mx-auto lg:mx-0'>
              <button className='btn btn-lg' onClick={scrollToContact}>Contact Me</button>
                <a href='https://github.com/suhejbmusliu' className='text-gradient btn-link'>
                  My Portofolio
                </a>
              </motion.div>
              {/* Social */}

              <motion.div variants={fadeIn('up', 0.7)} initial='hidden' whileInView={'show'} viewport={{once: false, amount: 0.7}}   className='flex text-[40px] gap-x-6 max-w-max mx-auto lg:mx-0'>
                <a href='https://github.com/suhejbmusliu'>
                  <FaGithub/>
                </a>
                <a href='https://www.linkedin.com/in/suhejb-musliu-8875691a8/'>
                  <FaLinkedin/>
                </a>
                <a href='https://www.facebook.com/suhejb.01?mibextid=LQQJ4d'>
                  <FaFacebook/>
                </a>
              </motion.div>

          </div>

          {/* image */}
          <motion.div variants={fadeIn('down', 0.8)} initial='hidden' whileInView={'show'}  className='hidden lg:flex flex-1 max-w-[320px] lg:max-w-[482x]'>
            <img src={Image} alt='' width='500' />
          </motion.div>

        </div>
      </div>
    </section>
  );
};

export default Banner;
//minuta 33;17